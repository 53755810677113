<template>
  <div class="user-new ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack()"></span>修改密码</div>
    <div class="image"></div>
    <div class="sub-tips">设置您的新密码。</div>
    <div class="center">
      <input type="password" class="ly-input key" v-model="form.oldpassword" placeholder="请输入原来的密码">
      <div class="input-tips">请先输入原来的密码。</div>
      <input type="password" class="ly-input key" v-model="form.confirmpassword" placeholder="请输入新密码">
      <div class="input-tips">密码为6~12位，由字母、数字或符号组成。</div>
      <input type="password" class="ly-input key" v-model="form.password" placeholder="请再次输入">
      <div class="input-tips">为确保安全，请再次输入新密码。</div>
      <button class="ly-btn choose" type="button" @click="handleSure">确认</button>
    </div>
  </div>
</template>
<script>

import {putModifyPassword} from '../../api/api.js'
import {getUserInfo} from '../../utils/auth'
import {Toast} from 'mint-ui';

export default {
  name: 'UserNew',
  components: {},
  computed: {},
  data() {
    return {
      form: {
        oldpassword: '',
        confirmpassword: '',
        password: '',
      }
    }
  },
  mounted() {

  },
  created() {

  },
  methods: {

    handleSure() {

      if (!this.form.oldpassword) {
        Toast("请先输入原来的密码！")
        return
      }

      if (this.form.confirmpassword && this.form.password && this.form.confirmpassword !== this.form.password) {
        Toast("两次输入的密码不一样！")
      } else {
        const info = getUserInfo()
        const body = Object.assign(this.form, {username: info.username})
        putModifyPassword(body).then(res => {
          Toast("修改密码成功，请重新登录！")
          this.goWay("/login")
        })
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">
.user-new {
  width: 100%;
  height: auto;
  background: #FFFFFF;

  .image {
    width: 100%;
    height: 2.4rem;
    background: url("../../assets/img/user/password.png") no-repeat bottom center;
    background-size: 1.18rem;
  }

  .sub-tips {
    line-height: 0.8rem;
    text-align: center;
    font-size: 0.22rem;
    font-weight: 400;
    color: #333333;
  }

  .center {
    width: 5.89rem;
    height: auto;
    margin: 0.1rem auto;

    .ly-input {
      width: 5rem;
      padding-left: 0.89rem;
      height: 0.88rem;
      border: 1px solid #999999;
      box-shadow: 0 0.05rem 0.12rem 0 rgba(0, 0, 0, 0.18);
      border-radius: 0.1rem;
      outline: none;
      //text-indent: 0.9rem;
      background-repeat: no-repeat;
      background-color: #FFFFFF;
      background-size: 0.28rem;
      background-position: 0.36rem center;
      margin: 0.2rem auto 0;
      background-image: url("../../assets/img/user/i-key.png");
    }

    .input-tips {
      font-size: 0.22rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.6rem;
    }


    .ly-btn {
      width: 5.89rem;
      height: 0.88rem;
      margin: 0.2rem auto;
      background: #0088FF;
      box-shadow: 0 0.1rem 0.13rem 0 rgba(0, 136, 255, 0.34);
      border-radius: 0.1rem;
      outline: none;
      border: none;
      color: #FFFFFF;
      font-size: 0.36rem;
    }
  }

}
</style>
